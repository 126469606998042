
// -----------------------------------------------------------------------------
// This file contains variables related to menus for navbars of the site/application.
// -----------------------------------------------------------------------------

$main-menu-item-height: 110px;
$main-menu-width: 160px;
$main-menu-width-lg: 160px;
$main-menu-width-md: 160px;
$main-menu-width-xs: 160px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 120px;
$navbar-height-xs: 80px;
$navbar-height-md: 90px;
$navbar-height-lg: 100px;

$main-margin: 20px;
$main-margin-lg: 20px;
$main-margin-md: 20px;
$main-margin-xs: 20px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;