// -----------------------------------------------------------------------------
// This file contains all styles related to dropdowns.
// -----------------------------------------------------------------------------

@use "../base/colors";

.dropdown-toggle {
  color: colors.$color-primary-main;
  text-decoration: none;
  font-size: 16px;
}

.dropdown-toggle::after {
  display: none !important;
}