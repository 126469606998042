// -----------------------------------------------------------------------------
// This file contains all styles related to the form wizard.
// -----------------------------------------------------------------------------

@use '../base/colors';
@use '../components/shadows';

$separator-color-light: colors.$color-gray-300;
$separator-color: colors.$color-gray-300;
$theme-color-1: colors.$color-primary-200;

/* forms */

form,
textarea,
ng-select,
input[type=text],
input[type=number]  {
  &.min-auto {
    min-width: auto!important;
  }
}

/* 21.Form Wizard */
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator {
  top: initial;
  bottom: -17px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):before {
  top: initial;
  bottom: -9px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator {
  padding-top: 0;
  padding-bottom: 15px;
  margin-bottom: 40px;
}

aw-wizard-navigation-bar ul.steps-indicator li a .label {
  text-transform: initial;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.optional .step-indicator,
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator {
  background-color: colors.$color-primary-100;
  border: 2px solid colors.$color-primary-200;
  width: 19px !important;
  height: 19px !important;

  &:after {
    content: "";
    background: initial;
  }
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable a:hover .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable a:hover .step-indicator {
  background-color: $separator-color-light !important;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):before,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):before {
  background-color: $separator-color-light;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label,
aw-wizard-navigation-bar.vertical ul.steps-indicator li a .label {
  padding-top: 0;
  padding-bottom: 10px;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.done .step-indicator {
  background-color: $separator-color-light;

  &:after {
    content: " ";
    position: relative;
    background: initial;
    width: 12px;
    height: 12px;
    // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='")+encodecolor($theme-color-1)+"' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    display: block;
  }
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator,
aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator {
  background-color: $theme-color-1;

  &:after {
    content: "";
    background: initial;
  }
}

aw-wizard.horizontal aw-wizard-step {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

aw-wizard.horizontal .complete-message-centered {
  top: calc(50% - 20px);
  position: relative;
  transform: translateY(-50%);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator {
  padding-top: 40px;
  padding-bottom: 15px;
}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):before {
  left: -5px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator {
  border-width: 1px !important;
  left: calc(50% - 20px) !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  top: -40px !important;
  border-color: $separator-color-light;
  font-size: 16px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li a .label {
  padding-top: 10px;
  padding-bottom: 0px;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):before {
  background-color: $separator-color-light;
  width: calc(100% - 40px);
  top: -20px;
  left: calc(50% + 20px);
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing .step-indicator {
  border-color: $theme-color-1;
  color: $theme-color-1;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  border-color: $theme-color-1;
  color: $theme-color-1;
}

aw-wizard li.current a .label {
  color: $theme-color-1 !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  color: $separator-color;
  border-color: $separator-color;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done.current .step-indicator {
  border-color: $theme-color-1;
  color: $theme-color-1;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator,
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator {
  border-color: $theme-color-1;
  color: $theme-color-1;
}

.wizard-title {
  font-size: 18px;
  font-weight: 800;
}

.btn.btn-primary, .btn.btn-primary-accent, .btn.btn-light {
  box-shadow: shadows.$shadow-100;
}

.form-check-inline {
  margin-left: 0;
}
.form-check-input {
  float: left !important;
  margin: 2px 5px 0 0;
}
.ng-select {
  border: none;
}
