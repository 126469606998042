@use '../base/colors';
@use '../abstracts/mixins';
@use '../abstracts/variables';

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

$foreground-color: colors.$color-white;

button {
  border-color: colors.$color-primary-100;
  box-shadow: initial;
  color: colors.$color-primary-main;
  // box-shadow: none;
}


/* 02.Buttons */

.btn {
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  outline: none !important;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
      0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
  }
}

.btn-empty {
  background: transparent !important;
}

.btn.default {
  border-radius: 0.1rem;
}

.btn-primary,
a.btn-primary:not([href]):not([tabindex]) {
  background-color: colors.$color-primary-main;
  border-color: colors.$color-primary-main;
  color: colors.$color-white;

  &:hover {
    color: colors.$color-white;
    background-color: darken(colors.$color-primary-main, 8%);
    border-color: darken(colors.$color-primary-main, 8%);
  }
}

.dropdown-menu {
  font-size: 1rem;
  border-radius: 0.1rem;
  // background: $input-background;
  // border-color: rgba($primary-color, 0.15);
}

.dropdown-item {
  padding: 0.5rem 1.25rem;
  color: colors.$color-primary-main;
}

// .dropdown-item:hover,
// .dropdown-item:focus {
//   background-color: $background-color;
//   color: $primary-color;
// }

// .dropdown-item.active,
// .dropdown-item:active {
//   color: $button-text-color !important;
//   text-decoration: none;
//   background-color: $theme-color-1;
// }

.dropdown-divider {
  border-color: colors.$color-primary-main;
}

.dropdown-menu.dropdown-menu-sm {
  min-width: 4rem;

  .dropdown-item {
    padding: 0.25rem 1rem;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: darken(colors.$color-primary-main, 12%);
  border-color: darken(colors.$color-primary-main, 12%);
  color: colors.$color-white;
}

.btn-secondary,
a.btn-secondary:not([href]):not([tabindex]) {
  background-color: colors.$color-secondary-main;
  border-color: colors.$color-secondary-main;
  color: colors.$color-white;

  &:hover {
    color: colors.$color-white;
    background-color: darken(colors.$color-secondary-main, 8%);
    border-color: darken(colors.$color-secondary-main, 8%);
  }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  background-color: darken(colors.$color-secondary-main, 12%);
  border-color: darken(colors.$color-secondary-main, 12%);
  color: colors.$color-white;
}

.btn-primary.btn-primary-gradient {
  background: linear-gradient(to right,
    colors.$color-primary-main,
    darken(colors.$color-primary-main, 15%));

  &:hover {
    background: linear-gradient(to right,
      colors.$color-primary-main,
      darken(colors.$color-primary-main, 8%));
  }
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-primary-gradient.dropdown-toggle {
  background: linear-gradient(to right,
    colors.$color-primary-main,
    darken(colors.$color-primary-main, 15%));
}

.btn-secondary-gradient {
  background: linear-gradient(to right,
    colors.$color-secondary-main,
    darken(colors.$color-secondary-main, 15%));

  &:hover {
    background: linear-gradient(to right,
      colors.$color-secondary-main,
      darken(colors.$color-secondary-main, 8%));
  }
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show>.btn-secondary-gradient.dropdown-toggle {
  background: linear-gradient(to right,
    colors.$color-secondary-main,
    darken(colors.$color-secondary-main, 15%));
}

.btn-warning {
  background-color: colors.$warning-color;
  border-color: colors.$warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
  color: colors.$color-white;

  &:hover {
    color: colors.$color-white;
  }
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
  &:hover {
    color: $foreground-color;
  }
}

.btn-light {
  color: colors.$color-primary-main;
  background-color: colors.$color-white;
  border-color: colors.$color-white;

  &:hover {
    color: colors.$color-primary-main;
    background-color: darken(colors.$color-white, 8%);
    border-color: darken(colors.$color-white, 8%);
  }
}


.btn-outline-primary {
  color: colors.$color-primary-main;
  border-color: colors.$color-primary-main;

  &:hover {
    color: darken(colors.$color-primary-main, 8%);
    background-color: darken(colors.$color-white, 8%);
    border-color: colors.$color-primary-main;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: colors.$color-primary-main;
  border-color: colors.$color-primary-main;
  color: colors.$color-white;
}

.btn-xl,
.btn-group-xl>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg>.btn {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.6rem 2.5rem 0.6rem 2.5rem;

  @include mixins.respond-below(xs) {
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  }
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.45rem 1rem 0.45rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 0.25rem 0.75rem;
  font-size: 0.86rem;
  line-height: 1.3;
}

// TODO: These disabled styles may need to be updated, get clarification from Andy
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: colors.$color-primary-main;
  border-color: colors.$color-primary-main;
  color: colors.$color-white;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: colors.$color-secondary-main;
  border-color: colors.$color-secondary-main;
  color: colors.$color-white;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-link {
  color: colors.$color-primary-main;
  text-decoration: initial !important;
}

.white-underline-link {
  color: colors.$color-white;
  text-decoration: underline;

  &:hover,
  &:active {
    color: colors.$color-white;
    text-decoration: initial;
  }
}

.btn-link:hover {
  color: rgba(colors.$color-primary-main, 0.7);
}

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms;

  .spinner,
  .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    // This used to be white
    color: colors.$color-primary-main;
    pointer-events: none;
  }

  .icon i {
    vertical-align: text-bottom;
    font-size: 18px;
  }

  .label {
    transition: opacity 500ms;
  }

  &.show-spinner {
    .label {
      opacity: 0;
    }

    .spinner {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-success {
    .label {
      opacity: 0;
    }

    .icon.success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.show-fail {
    .label {
      opacity: 0;
    }

    .icon.fail {
      opacity: 1;
      visibility: visible;
    }
  }

  &.btn-primary:disabled {
    opacity: 1;
    background: darken(colors.$color-primary-main, 10%);
    border-color: darken(colors.$color-primary-main,  10%);
  }

  &.btn-secondary:disabled {
    opacity: 1;
    border-color: darken(colors.$color-secondary-main,  10%);
  }
}

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;

  &.large {
    width: 44px;
    height: 44px;
    font-size: 18px;
    line-height: 2.5;
  }

  &.small-icon {
    font-size: 12px;
    line-height: 32px;
  }
}

.top-right-button-single {
  width: unset;

  @include mixins.respond-below(md) {
    width: calc(100%);
  }
}

.top-right-button-container {
  float: right;
  position: relative;

  .check-button .custom-control-label::before {
    border: 1px solid colors.$color-primary-main !important;
  }

  .btn .custom-control-input:checked~.custom-control-label::before {
    border: 1px solid colors.$color-primary-main !important;
  }

  @include mixins.respond-below(xs) {
    float: initial;
    display: flex;
    justify-content: space-between;
  }

  .top-right-button {
    @include mixins.respond-below(xs) {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }
  }
}

.btn-rounded-4 {
  border-radius: 4px !important;
}

.btn-rounded-20 {
  border-radius: variables.$border-radius-20 !important;
}

.btn-dark-gray-button {
  background-color: colors.$color-gray-400;
  color: colors.$color-primary-main;
  box-shadow: 5px 5px 20px rgba(16, 25, 106, 0.08);
  transition: background-color .4s;

  &.exists {
    background-color: #EEF0F4;
    color: #4E5467;
    border: 1px solid #4E5467;
  }

  &.active {
    color: colors.$color-white;
    background-color: colors.$color-primary-accent-main;
  }
}

.btn-dark-gray-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.btn-primary-accent {
  background-color: colors.$color-primary-accent-main;
  color: colors.$color-white;
  box-shadow: 5px 5px 20px rgba(16, 25, 106, 0.08);
}

.page-item {
  padding: 10px;

  @include mixins.respond-below(sm) {
    padding: 3px;
  }

  .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    // border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0;

    @include mixins.respond-below(xs) {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.76rem;
    }
  }

  &.active .page-link {
    background: transparent;
    border: 1px solid colors.$color-primary-main;
    color: colors.$color-primary-main;
  }

  &.disabled .page-link {
    border-color: colors.$separator-color;
    color: colors.$separator-color;
    background: transparent;
  }
}

.page-item.pagination-next,
.page-item.pagination-prev {
  .page-link {
    background: colors.$color-primary-main;
    color: black;
    border: 1px solid colors.$color-primary-main;

    &:hover {
      background-color: transparent;
      border-color: lighten(colors.$color-primary-main, 10%);
      color: colors.$color-primary-main;
    }
  }
}

.page-item.pagination-last,
.page-item.pagination-first {
  .page-link {
    background: transparent;
    color: colors.$color-primary-main;
    border: 1px solid colors.$color-primary-main;
    border-radius: 30px;

    &:hover {
      background: colors.$color-primary-main;
      color: black;
      border: 1px solid colors.$color-primary-main;
    }
  }
}

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: colors.$color-primary-main;
}

.page-link:hover {
  background: colors.$color-primary-main;
  color: black;
  border: 1px solid colors.$color-primary-main;
}

.btn-sm.page-link {
  padding: 0.5rem 0.5rem;
}

.pagination-lg {
  .page-item {
    padding: 15px;

    @include mixins.respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 50px;
      height: 50px;

      @include mixins.respond-below(sm) {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.8rem;
      }

      @include mixins.respond-below(xs) {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem;
      }
    }
  }
}

.pagination-sm {
  .page-item {
    padding: 6px;

    @include mixins.respond-below(sm) {
      padding: 3px;
    }

    .page-link {
      min-width: 30px;
      height: 30px;
      line-height: 0.9;
      font-size: 0.75rem;
      padding: 0.5rem 0;
    }
  }
}

.modal-content > button.btn {
  border-radius: 20px;
  margin-right: 25px;
  min-width: 120px;
}

.modal-content > button.btn:last-child {
  margin-right: 0;
}

.display-tools button.btn {
  background-color: colors.$color-primary-accent-main;
  color: #FFFFFF;
  opacity: 0.8;
  margin: 1px;
  width: 100%;

  .btn-sm.toolbar-icons {
    color: #FFFFFF;
  }
}

.display-tools button.btn.btn-sm.toolbar-icons.active,
.display-tools button.btn.btn-sm.toolbar-icons.isActive {
  background-color: colors.$color-primary-accent-main;
}

.display-tools button.btn.btn-sm.toolbar-icons:focus {
  box-shadow: none;
}

#close-button {
  position: relative;
  top: 25%;
  vertical-align: baseline;
  padding: 0.5rem;
}

#close-button:hover {
  background-color: colors.$color-gray-200;
}

.dropdown-toggle.header-button {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 100px 0 -8px;
  padding: 0;
}

.switch-container {
  display: inline-block;
  padding: 6px 15px;
  font-weight: bold;
}

.switcher {
  display: inline-flex;
  background-color: #D1DFEC;
  height: 20px;
  width: 40px;
  border-radius: 10px;
  margin: 0 5px;
  cursor: pointer;
  vertical-align: bottom;
}

.switcher.circle-right {
  justify-content: end;
}

.switcher .circle {
  background-color: colors.$color-primary-main;
  height: 20px;
  width: 20px;
  border-radius: 10px;
}


