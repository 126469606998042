// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

@use './colors';
@use '../abstracts/mixins';
@use "src/styles/abstracts/variables" as *;

// Remove browser arrows from numbers
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-check-input[type=checkbox] {
  float: left !important;
  margin: 3px 5px 0 0;
}

.form-check-label, .form-check-input[type=checkbox] {
  cursor: pointer;
}

.logo-nav {
  width: 190px;
}

.block {
  display: block !important;
}

.list-unstyled {
  padding-right: 0;
  list-style: none;
}

.form-reqd {
  color: colors.$color-primary-accent-main;
}

.form-error {
  color: colors.$error-color;
  display: inline-block;
  font-style: italic;
  margin-left: 2px;
}

.calc-gray-text {
  font-size: 16px;
  color: colors.$color-gray-500;
}

.login-page {
  background: url("/assets/images/login_bg.svg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .container {
    margin: auto;
    padding: 0;
  }
}

.login-centered {
  display: flex;
  min-height: 100vh;
  align-items: center !important;
  flex: 1 auto;
  flex-wrap: nowrap;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.mfa-page {
  background: url("/assets/images/login_bg.svg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .container {
    margin: auto;
    padding: 0;
  }
}

.mfa-centered {
  display: flex;
  min-height: 100vh;
  align-items: center !important;
  flex: 1 auto;
  flex-wrap: nowrap;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.auth-card {
  display: flex;
  flex-direction: row;
  padding: 0;
  box-shadow: none;
  @include mixins.depth(1);

  .form-side {
    width: 60%;
    padding: 3.2em;
  }

  .momBabyImage {
    background: url("/assets/images/login_fg.png") no-repeat center top;
    background-size: cover;
    min-height: 420px;
    padding: 0;
    margin: 0 -1px -1px -1px;
    border-radius: 0.25rem 0 0 0.25rem;

    .h3 {
      line-height: 0.8rem;
    }

    @include mixins.respond-below(md) {
      min-height: 330px;
      margin: 0;
      border-radius: 0.25rem 0.25rem 0 0;
    }
  }

  @include mixins.respond-below(md) {
    flex-direction: column;

    .form-side {
      width: 90%;
      padding: 2.2em;
    }
  }
}

.main-outlet-transparent {
  margin: 15px 15px;
  padding: 0 15px;
  background-color: colors.$color-gray-100 !important;
  box-shadow: none !important;
  min-height: 50vh;
}

.underline {
  text-decoration: underline colors.$color-gray-300 1px;
  text-decoration-skip-ink: none;
}

.modal-backdrop {
  background-color: #9AA4B8;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-content {
  border: 2px solid #4E5467;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
}

#force-print-headers > thead > tr,
#force-print-headers > thead > tr > td,
#force-print-headers > tbody > tr,
#force-print-headers > tbody > tr > td {
  border: none !important;
}

// .print-header {
//   display: none;
// }

.screen-header {
  padding-bottom: 10px;
  padding-right: 10px;

  .report-header-bottom {
    padding-bottom: 2px;
    border-bottom: 1px solid colors.$color-primary-main;

    h2 {
      margin: 0;
      line-height: 20px
    }
  }
  .report-type {
    color: colors.$color-primary-400;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;

    span {
      margin: 0;
      line-height: 16px;
    }

    .destroy-text {
      font-weight: normal;
      font-size: 12px;
    }
  }

  .patient-info {
    font-size: 1.1em;
  }

  .report-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }

  .report-address {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 0;
    line-height: 24px;
  }

  .annotation {
    width: 95%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .annotation-disabled {
    width: 95%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0,0,0,0);
    position: absolute;
  }
}

#intake-form-wrapper ul.steps-indicator {
  width: 50%;
  color: colors.$color-primary-main;
}

#intake-form-wrapper ul.steps-indicator li a .label {
  color: colors.$color-primary-main !important;
}

#intake-form-wrapper ul.steps-indicator li .step-indicator {
  background-color: white !important;
  border: 2px solid colors.$color-primary-main !important;
}

#intake-form-wrapper ul.steps-indicator li.done .step-indicator {
  background-color: colors.$color-primary-main !important;
  border: none !important;
}

#intake-form-wrapper ul.steps-indicator li.current .step-indicator {
  background-color: colors.$color-primary-accent-main !important;
  border: none !important;
}

//modals
.wide-modal-dialog {
  width:100%;
//  max-width: 54vw;
  max-width: 54vmax;
  margin: 0 auto;
}

.wide-modal-dialog .modal-content{
  width:100% ;
  height: 100%  ;
}

.modal-body.dialog-sweep {
  padding: 0 5px 5px 5px;
  margin-top: -2px;
}

.diagnostics-modal .btn {
  border-radius: 20px;
  margin-right: 25px;
  min-width: 120px;
}

.previous-order-modal {
  min-width: 1350px;
  max-width: 95vw;
  width: fit-content;
}

.width-100-nb {
  width: 100px;
  border: none;
}

.img-multi {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.annotated-img {
  padding: 4px;
}

.secondary-blue-text {
  color: $secondary-blue;
}

button:focus {
  box-shadow: none !important;
}