@use '../base/colors';

$theme-color-1: colors.$color-primary-main;
$theme-color-2: colors.$color-primary-200;
$theme-color-3: colors.$color-primary-300;

$primary-color: colors.$color-primary-main;

.text-theme-2 {
  color: $theme-color-2 !important;
}

.text-theme-3 {
  color: $theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
  color: $theme-color-1 !important;
}

.text-secondary {
  color: $primary-color !important;
}

.text-white {
  color: colors.$color-white !important;
}

.bg-primary {
  background-color: colors.$color-primary-accent-main !important;
}