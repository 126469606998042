@use 'src/styles/base/colors';
@media print {
  // bootstrap print
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  tr {
    orphans: 0;
    widows: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    break-after: avoid;
  }
  @page {
    size: a3;
    margin-top: 0;
    background-color: white;
  }
  body {
    min-width: 992px !important;
    overflow: hidden;
    background-color: white;
  }
  .container {
    min-width: 992px !important;
    overflow: hidden;
    background-color: white;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
    overflow-x: hidden;
    background-color: white;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  // ****************
  // bbimaging print
  // *************
  .print-area * {
    visibility: visible;
  }
  .print-area  {
    background-color: white;
  }
  .screen-header {
    display: none;
  }
  .print-header, .header-space {
    height: 100px;
    // padding-top: 30px;
    width: 100%;
  }
  .print-header {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    // padding-bottom: 10px;
    // padding-right: 35px;

    // .report-header-bottom {
    //   padding-bottom: 2px;
    //   border-bottom: 1px solid colors.$color-primary-main;

    //   h2 {
    //     margin: 0;
    //     line-height: 20px
    //   }
    // }

    // .report-type {
    //   color: colors.$color-primary-400;
    //   font-weight: bold;
    //   font-size: 24px;
    //   line-height: 24px;
    //   -webkit-print-color-adjust: exact;

    //   span {
    //     margin: 0;
    //     line-height: 16px;
    //   }

    //   .destroy-text {
    //     font-weight: normal;
    //     font-size: 12px;
    //   }
    // }

    .patient-info {
      font-size: 1.1em;
    }

    .report-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
    }

    .report-address {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      margin: 0;
      padding: 0;
      line-height: 24px;
    }
  }
  body {
    position: relative;
    // visibility: hidden;
  }
  .navbar, .nav, .no-print {
    display: none;
  }
  table  {
    > table {
      page-break-after: always;
      break-after: page;
    }
  }

  #force-print-headers > thead > tr,
  #force-print-headers > thead > tr > td,
  #force-print-headers > tbody,
  #force-print-headers > tbody > tr {
    // page-break-inside: avoid;
    // break-inside: avoid;
    // widows: 3;
    orphans: 1;
    // background-color: white;
  }
  #force-print-headers {
    background-color: white;

    > thead {
      // display: table-header-group;
      // break-inside: avoid;
      width: 100%;
    }
    > tfoot {
      display: table-footer-group;
      break-inside: avoid;
    }
    .report-content {
      background-color: white;
      // page-break-after: always;
      position: relative;
      // top: calc(100vh * -1);
    }
  }

  .pageBreakBefore {
    page-break-before: always;
    break-before: page;
  }

  .no-break {
    page-break-inside: avoid;
    break-inside: avoid;
    widows: 3;
    orphans: 3;
  }
  .never-break {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  .main-outlet {
    padding: 0;
    min-height: auto;
  }

  .content-footer {
    width: 100%;
    // height: 75px;
    // padding: 40px 20px 0 20px;
    // vertical-align: middle;
    // font-size: 15px;
    // margin-top: 0;
    // text-align: right;
    // print-color-adjust: exact;
    // -webkit-print-color-adjust: exact;
  }

  .break-word {
    word-break: break-all;
  }
}

