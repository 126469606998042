// -----------------------------------------------------------------------------
// This file contains all styles related to the sidebar (which will be removed in later versions) of the site/application.
// -----------------------------------------------------------------------------
@use "../abstracts/variables";
@use "../base/colors";

@use '../abstracts/mixins';

$foreground-color: colors.$color-gray-100;
$background-color: colors.$color-primary-300;
$theme-color-1: colors.$color-primary-main;
$primary-color: colors.$color-primary-main;
$secondary-color: colors.$color-primary-300;
$separator-color-light: colors.$color-primary-100;

$main-menu-item-height: 110px;
$main-menu-width: 160px;
$main-menu-width-lg: 160px;
$main-menu-width-md: 160px;
$main-menu-width-xs: 160px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 120px;
$navbar-height-xs: 80px;
$navbar-height-md: 90px;
$navbar-height-lg: 100px;

$main-margin: 20px;
$main-margin-lg: 20px;
$main-margin-md: 20px;
$main-margin-xs: 20px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: $navbar-height;
  z-index: 5;
  height: calc(100% - #{$navbar-height});
  transition: border-radius $menu-collapse-time;

  .main-menu {
    width: $main-menu-width;
    height: calc(100% - #{$navbar-height});
    background: $foreground-color;
    z-index: 3;
    position: fixed;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 0;

    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%;
      --scrollbar-color: transparent;
      --scrollbar-container-color: transparent;
      --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);
      --scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.3);
      --scrollbar-border-radius: 4px;
      --scrollbar-size: 6px;
      --scrollbar-padding: 0px;
      --scroll-view-margin: 0;
      --scroll-view-color: transparent;

      .ps__thumb-y {
        right: 0;
      }
    }

    ul {
      margin-bottom: initial;

      li {
        position: relative;

        span {
          text-align: center;
          padding: 0 10px;
          line-height: 14px;
        }

        a {
          height: $main-menu-item-height;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          border-bottom: 1px solid $separator-color-light;
          color: $primary-color;
          transition: color $menu-collapse-time;
          transition: background $menu-collapse-time;

          &:hover,
          &:focus {
            color: $theme-color-1;
          }
        }

        i {
          font-size: 52px;
          line-height: 62px;
        }

        &.active a {
          color: $theme-color-1;
        }

        &.active:after {
          content: " ";
          background: $theme-color-1;
          color: $foreground-color;
          border-radius: 10px;
          position: absolute;
          width: 6px;
          height: 90px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }

        &:last-of-type {
          a {
            border-bottom: initial;
          }
        }
      }
    }

    @include mixins.respond-below(xl) {
      width: $main-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include mixins.respond-below(lg) {
      width: $main-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include mixins.respond-below(sm) {
      width: $main-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});

      ul li i {
        font-size: 26px;
        line-height: 30px;
      }

      ul li a {
        height: $main-menu-item-height-mobile;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }

  .sub-menu {
    width: $sub-menu-width;
    background: $foreground-color;
    z-index: 2;
    position: fixed;
    left: $main-menu-width;
    border-left: 1px solid $separator-color-light;
    height: calc(100% - #{$navbar-height});
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;

    .scroll {
      margin-right: unset;
      padding-right: unset;
      height: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 5px;

      .ps__thumb-y {
        right: 0;
      }
    }

    ul {
      display: none;

      li {
        margin-left: 30px;
        margin-bottom: 0;
        position: relative;

        a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
          margin-bottom: 10px;

          span {
            vertical-align: middle;
            height: 16px;
          }
        }

        i {
          font-size: 1.3em;
          margin-right: 10px;
          color: $secondary-color;
          vertical-align: middle;
          display: inline-block;
        }

        &.active i,
        &.active a {
          color: $theme-color-1;
        }

        &.active:after {
          content: " ";
          background: $theme-color-1;
          border-radius: 10px;
          position: absolute;
          width: 4px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          left: -16px;
        }
      }
    }

    ul.inner-level-menu {
      display: block !important;

      li {

        a,
        i {
          color: $primary-color;
        }

        a:hover,
        &.active i,
        &.active a {
          color: $theme-color-1;
        }

        &.active:after {
          left: -22px;
        }
      }
    }

    .rotate-arrow-icon {
      i {
        transition: transform $animation-time-short;
        transform: rotate(0);
      }

      &.collapsed {
        i {
          transform: rotate(-90deg);
        }
      }
    }

    @include mixins.respond-below(xl) {
      left: $main-menu-width-lg;
      width: $sub-menu-width-lg;
      height: calc(100% - #{$navbar-height-lg});
    }

    @include mixins.respond-below(lg) {
      left: $main-menu-width-md;
      width: $sub-menu-width-md;
      height: calc(100% - #{$navbar-height-md});
    }

    @include mixins.respond-below(sm) {
      left: $main-menu-width-xs;
      width: $sub-menu-width-xs;
      height: calc(100% - #{$navbar-height-xs});
    }
  }

  @include mixins.respond-below(xl) {
    padding-top: $navbar-height-lg;
    height: calc(100% - #{$navbar-height-lg});
  }

  @include mixins.respond-below(lg) {
    padding-top: $navbar-height-md;
    height: calc(100% - #{$navbar-height-md});
  }

  @include mixins.respond-below(sm) {
    padding-top: $navbar-height-xs;
    height: calc(100% - #{$navbar-height-xs});
  }
}

body.default-transition .menu .sub-menu {
  transition: transform $menu-collapse-time;
}

body.default-transition .menu .main-menu {
  transition: transform $menu-collapse-time, border-radius $menu-collapse-time;
}

//Menu Config Main Menu Sub Menu

.sub-hidden .sub-menu,
.menu-sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(- $sub-menu-width);

  @include mixins.respond-below(xl) {
    transform: translateX(- $sub-menu-width-lg);
  }

  @include mixins.respond-below(lg) {
    transform: translateX(- $sub-menu-width-md);
  }

  @include mixins.respond-below(sm) {
    transform: translateX(- $sub-menu-width-xs);
  }
}

.main-hidden .main-menu,
.menu-hidden .main-menu {
  transform: translateX(- $main-menu-width);

  @include mixins.respond-below(xl) {
    transform: translateX(- $main-menu-width-lg);
  }

  @include mixins.respond-below(lg) {
    transform: translateX(- $main-menu-width-md);
  }

  @include mixins.respond-below(sm) {
    transform: translateX(- $main-menu-width-xs);
  }
}

.main-hidden.sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width - $main-menu-width);

  @include mixins.respond-below(xl) {
    transform: translateX(- $sub-menu-width-lg - $main-menu-width-lg);
  }
  @include mixins.respond-below(lg) {
    transform: translateX(- $sub-menu-width-md - $main-menu-width-md);
  }

  @include mixins.respond-below(sm) {
    transform: translateX(- $sub-menu-width-xs - $main-menu-width-xs);
  }
}
//Mobile
.menu-mobile {
  .main-menu {
    transform: translateX(- $main-menu-width-xs);
  }

  .sub-menu {
    transform: translateX(- $sub-menu-width-xs - $main-menu-width-xs - 50);
  }
}

//Show Temporary
.main-show-temporary {
  .main-menu {
    transform: translateX(0);
  }

  .sub-menu {
    transform: translateX(- $sub-menu-width);
  }

  @include mixins.respond-below(xl) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(- $sub-menu-width-lg);
    }
  }

  @include mixins.respond-below(lg) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(- $sub-menu-width-md);
    }
  }

  @include mixins.respond-below(sm) {
    .sub-menu {
      transform: translateX(- $sub-menu-width-xs);
    }
  }
}

.sub-show-temporary,
.menu-mobile.sub-show-temporary {
  .sub-menu {
    transform: translateX(0);
  }
}

.app-menu {
  z-index: 1;
  @include mixins.depth(1);
  width: $app-menu-width;
  height: 100%;
  float: right;
  background: $foreground-color;
  transition: transform $menu-collapse-time;
  position: fixed;
  right: 0;
  top: $navbar-height;
  height: calc(100% - #{$navbar-height});

  @include mixins.respond-below(xl) {
    top: $navbar-height-lg;
    height: calc(100% - #{$navbar-height-lg});
  }

  @include mixins.respond-below(lg) {
    top: $navbar-height-md;
    transform: translateX($app-menu-width);
    height: calc(100% - #{$navbar-height-md});
  }

  @include mixins.respond-below(sm) {
    top: $navbar-height-xs;
    height: calc(100% - #{$navbar-height-xs});
  }

  .scroll {
    height: 100%;

    .ps__thumb-y {
      right: 0;
    }
  }

  &.shown {
    transform: translateX(0);
  }

  .app-menu-button {
    position: absolute;
    left: -28px;
    background: $foreground-color;
    top: $main-margin - 15;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: $secondary-color;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0;

    @include mixins.respond-below(xl) {
      top: $main-margin-lg - 14;
    }

    @include mixins.respond-below(lg) {
      top: $main-margin-md - 13;
    }

    @include mixins.respond-below(sm) {
      top: $main-margin-xs - 2;
    }
  }

  ul {
    li {
      margin-bottom: 5px;

      a {
        font-size: 13px;
        display: block;
        padding: 3px 0;

        &:hover i {
          color: $theme-color-1;
        }
      }

      i {
        font-size: 1.2em;
        margin-right: 10px;
        color: $secondary-color;
        vertical-align: unset;

        @include mixins.respond-below(sm) {
          font-size: 20px;
        }
      }

      &.active i,
      &.active a {
        color: $theme-color-1;
      }
    }
  }
}

.app-row {
  padding-right: $app-menu-width;

  @include mixins.respond-below(lg) {
    padding-right: 0;
  }
}
