// -----------------------------------------------------------------------------
// This file contains all styles related to tabs of the site/application.
// -----------------------------------------------------------------------------

@use '../base/colors';

$active-text-color: colors.$color-primary-accent-main;
$default-text-color: colors.$color-primary-main;
$background-color: colors.$color-gray-100;

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.4rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  padding-top: 6px;
  border: none;
  border-radius: 2em;
  padding-left: 20px;
  background-color: colors.$color-gray-100;

  .nav-item {
    margin-bottom: -1px;

    .btn {
      padding: 0.4rem 1.2rem 0.4rem 1.2rem;
      margin: -1px 1.3rem 0
    }
  }

  .nav-link {
    color: $default-text-color;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    border-top-left-radius: 0.7em;
    border-top-right-radius: 0.7em;
  }

  .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}


.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $active-text-color;
  background-color: colors.$color-white;
  border: none;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  padding: 20px;
  background-color: #9aa4b8;
  color: #FFFFFF;
  margin: 6px;
  font-size: 16px;
  text-align: center;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link.active:hover {
  color: $default-text-color;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: $active-text-color;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}


