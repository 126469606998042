// -----------------------------------------------------------------------------
// This file contains all colors for the application
// -----------------------------------------------------------------------------

// Primary colors
$color-primary-main: #171717; //slate
$color-primary-50: #E1EEF7;
$color-primary-100: #D1DFEC;
$color-primary-200: #74abcd;
$color-primary-300:#4f7ab8;
$color-primary-400: #507AB8;
$color-primary-600: #202a58;


$color-secondary-main: #29428A;  //sky
$color-primary-accent-main: #3395C8;  //gem

// Gray colors
$color-gray-100: #F3F4F5; //air
$color-gray-200: #ECEDF0; //stone
$color-gray-300: #C8D0E0;
$color-gray-400: #d8d8d8; // new gray
$color-gray-500: #4e5467;

$color-white: #FFFFFF;

// Warning/info colors
$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #EF233D;  //new error

// Separator color taken from theme
$separator-color: #424242;
