// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@use '../layout/menu';
@use "../base/colors";
@use '../abstracts/mixins';

$foreground-color: colors.$color-gray-100;
$background-color: colors.$color-primary-300;
$theme-color-1: colors.$color-primary-main;
$primary-color: colors.$color-primary-main;
$secondary-color: colors.$color-primary-300;

.navbar {
  background: $foreground-color;
  padding: 1.0rem 0 .5rem 0;
  position: sticky !important;

  .navbar-contents {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  .navbar-left {
    padding-bottom: 20px;

    @include mixins.respond-below(sm) {
     margin-left: 0px !important;
    }
  }

  #iconMenuDropdown {
    width: 220px;
    padding: 1.5rem 1rem;
    height: 290px;
  }

  .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.7rem;
    color: lighten($primary-color, 20%);

    i {
      font-size: 32px;
      line-height: 42px;
    }

    span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px;
    }

    &:hover,
    &:focus {
      color: $theme-color-1;
    }
  }

  .menu-button-mobile {
    color: $secondary-color;
    text-align: center;

    svg {
      height: 12px;
      fill: $primary-color;
    }

    margin-left: menu.$main-margin-xs;

    @include mixins.respond-below(sm) {
      width: 20px;
    }
  }

  .menu-button {
    color: $secondary-color;
    width: menu.$main-menu-width;
    text-align: center;

    svg {
      height: 12px;
    }

    .main {
      fill: $primary-color;
      transition: fill menu.$menu-collapse-time;
    }

    .sub {
      fill: $primary-color;
      transition: fill menu.$menu-collapse-time;
    }

    &:hover {
      color: $theme-color-1;
    }

    @include mixins.respond-below(xl) {
      width: menu.$main-menu-width-lg;
    }

    @include mixins.respond-below(lg) {
      width: menu.$main-menu-width-md;
    }

    @include mixins.respond-below(sm) {
      width: 60px;
    }
  }

  .navbar-left {
    flex-basis: 40%;
  }

  .navbar-right {
    flex-basis: 40%;
    text-align: right;

    .user-icons {
      @include mixins.respond-below(xl) {
        margin-right: menu.$main-margin-lg;
      }

      @include mixins.respond-below(lg) {
        margin-right: menu.$main-margin-md;
      }

      @include mixins.respond-below(sm) {
        margin-right: menu.$main-margin-xs;
      }
    }
  }

  .custom-control {
    .custom-control-label::before {
      border: 1px solid $secondary-color !important;
    }

    .custom-control-label::after {
      background-color: $secondary-color;
    }
  }

  .navmenu-icon-text {
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
  }

  .navmenu-icon-spacing {
    margin: 0px 10px;
  }

}

//Menu Config Button
#app-container {
// position: fixed !important;
  &.sub-hidden .menu-button,
  &.menu-sub-hidden .menu-button {
    .sub {
      fill: $secondary-color;
    }
  }

  &.main-hidden .menu-button,
  &.menu-hidden .menu-button {

    .main,
    .sub {
      fill: $secondary-color;
    }
  }
}

#user_menu_section{
  display: flex;
  flex-direction: column;
}

#user_menu{
  width: fit-content;
  align-self: flex-end;
  margin-bottom: 15px;
  margin-right: 5px;
  background: $secondary-color;
  color: white;
  border-radius: 10px;
  min-width: 130px;
  font-size: 12px;
  float: right;
}

.user_menu_link{
  padding: 3px 15px;
  text-align: right;
}

.user_menu_first{
  padding-top: 8px;
  border-radius: 10px 10px 0px 0px;
}

.user_menu_last{
  padding-bottom: 8px;
  border-radius: 0px 0px 10px 10px;
}

.user_menu_link:hover{
  background: $primary-color;
}

.user_menu_link:hover{
  background: $primary-color;
}

#user_menu_section{
  display: none;
  position: absolute;
  right: 20px;
  z-index: 9999;
}

.navbar-profile-link:hover + #user_menu_section,
#user_menu_section:hover{
 display: block;
}
