// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
@use '../layout/menu';
@use '../base/colors';
@use '../abstracts/mixins';


footer.page-footer {
  padding-bottom: 2.2rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.login-page .footer-content {
  color: white;
  white-space: nowrap;
}
.mfa-page .footer-content {
  color: white;
  white-space: nowrap;
}

.menu .sub-menu ul li.has-sub-item.active:after {
  content: " ";
  background: initial;
}

.cursor-pointer {
  cursor: pointer;
}


#app-container {

  &.sub-hidden .footer-content,
  &.menu-sub-hidden .footer-content,
  &.menu-hidden .footer-content {
    margin-left: menu.$main-menu-width + menu.$main-margin;
  }

  &.main-hidden .footer-content,
  &.menu-hidden .footer-content {
    margin-left: menu.$main-margin;
  }

  &.menu-main-hidden {
    .footer-content {
      margin-left: menu.$main-margin + menu.$sub-menu-width;
    }

    &.menu-hidden {
      .footer-content {
        margin-left: menu.$main-margin;
      }
    }
  }

  @include mixins.respond-below(xl) {

    &.sub-hidden .footer-content,
    &.menu-sub-hidden .footer-content,
    &.menu-hidden .footer-content {
      margin-left: menu.$main-menu-width-lg + menu.$main-margin-lg;
    }

    &.main-hidden .footer-content,
    &.menu-hidden .footer-content {
      margin-left: menu.$main-margin-lg;
    }

    &.menu-main-hidden {
      .footer-content {
        margin-left: menu.$main-margin-lg + menu.$sub-menu-width-lg;
      }

      &.menu-hidden {
        .footer-content {
          margin-left: menu.$main-margin-lg;
        }
      }
    }
  }

  @include mixins.respond-below(lg) {

    &.sub-hidden .footer-content,
    &.menu-sub-hidden .footer-content,
    &.menu-hidden .footer-content {
      margin-left: menu.$main-menu-width-md + menu.$main-margin-md;
    }

    &.main-hidden .footer-content,
    &.menu-hidden .footer-content {
      margin-left: menu.$main-margin-md;
    }

    &.menu-main-hidden {
      .footer-content {
        margin-left: menu.$main-margin-md + menu.$sub-menu-width-md;
      }

      &.menu-hidden {
        .footer-content {
          margin-left: menu.$main-margin-md;
        }
      }
    }
  }
}
