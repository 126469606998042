// -----------------------------------------------------------------------------
// This file contains all styles related to inputs (radio, checkboxes, text)
// -----------------------------------------------------------------------------

@use "../base/colors";

input {
  border-color: colors.$color-primary-main;
  outline: none !important;
  box-shadow: none !important;

  &.form-control {
    border-color: colors.$color-primary-main;
  }
}

.dropdown-menu .form-check-label {
  padding-left: 1.5em;
  white-space: pre;
}

.dropdown-menu .filter-item .form-check-label,  .dropdown-menu  #sortOrder .form-check-label{
  padding-left: 0;
  white-space: pre;
}

.form-check-label {
  padding-left: 0;
  white-space: pre;
}

/* Radio buttons */
.form-check-input {
  width: 1em;
  height: 1em;
  background-color: transparent;
  background-size: 150%;
  padding-left: 2em;
  border: 1.5px solid colors.$color-primary-main;
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: none !important;
  box-shadow: none !important;
}
.form-check-input:checked {
  background-color: colors.$color-white;
  border-color: colors.$color-primary-main;
}
.form-check-input:checked[type=radio] {
  background-image: url("/assets/icons/radio_selected.svg");
}

/* Checkboxes */
.form-check-input:checked[type=checkbox] {
  background-color: colors.$color-primary-main;
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: colors.$color-primary-main;
  border-color: colors.$color-primary-main;
}

textarea {
  outline: none !important;
  box-shadow: none !important;

  &.form-control {
    border-color: colors.$color-primary-main;
  }
}

::-webkit-resizer {
  border-color: colors.$color-primary-main;
}

/* Fetal Heart Rate */
.fhr-input {
  width: 70px;
  display: inline-block;
  margin-right: 5px;
}

input.datepicker.form-control,
input.datepicker.form-control {
  max-width: 150px !important;
}


