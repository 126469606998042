// -----------------------------------------------------------------------------
// This file contains all styles related to the shadows of the site/application.
// -----------------------------------------------------------------------------

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

// Meant to be used in the box-shadow css property
$shadow-100: 5px 5px 20px -3px rgba(38, 83, 132, 0.4);
