@use '../abstracts/variables';
@use '../base/colors';
@use '../abstracts/mixins';

$primary-color: colors.$color-primary-main;
$foreground-color: colors.$color-white;
$separator-color: colors.$color-primary-main;
$secondary-color: colors.$color-primary-300;

.popover {
  border-radius: variables.$border-radius;
  background-color: $foreground-color;
  border-color: $separator-color;

  .popover-body {
    color: $primary-color;
  }
}

.popover-header {
  background-color: transparent;
  border-bottom: initial;
}

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: $primary-color;
  background-color: $foreground-color;
  border-radius: 0.1rem;
  border: 1px solid $separator-color;
}

.tooltip.show {
  opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: $separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: $foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: $separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: $foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: $foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: $separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: $foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: $separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: $foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: $separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: $foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: $separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: $foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: $separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: $foreground-color;
}

.search-sm {
  position: relative;

  input {
    background: none;
    outline: initial !important;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    border: 1px solid $secondary-color;
    color: $primary-color;
  }

  &:after {
    font-family: "simple-line-icons";
    content: "\E090";
    font-size: 14px;
    color: $secondary-color;
    position: absolute;
    right: 10px;
    text-align: center;
    cursor: pointer;
    top: 2px;
  }
}

.invalid-tooltip,
.valid-tooltip,
div.error:not(.simple-notification) {
  border-radius: variables.$border-radius;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: $foreground-color;
  border: 1px solid colors.$color-primary-main;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  @include mixins.depth(1);
  display: block;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px colors.$color-primary-main;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px $foreground-color;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}

.tooltip-right-bottom .invalid-tooltip,
.tooltip-right-bottom .valid-tooltip,
.tooltip-right-bottom div.error {
  left: initial;
  right: 0;
  transform: translateX(0);
  top: initial;
  bottom: -33px;

  &::before,
  &::after {
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-center-bottom .invalid-tooltip,
.tooltip-center-bottom .valid-tooltip,
.tooltip-center-bottom div.error {
  left: 50%;
  right: initial;
  transform: translateX(-50%);
  top: initial;
  bottom: -33px;
}

.tooltip-left-bottom .invalid-tooltip,
.tooltip-left-bottom .valid-tooltip,
.tooltip-left-bottom div.error {
  left: 0;
  right: initial;
  transform: translateX(0);
  top: initial;
  bottom: -33px;

  &::before,
  &::after {
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-center-top .invalid-tooltip,
.tooltip-center-top .valid-tooltip,
.tooltip-center-top div.error {
  transform: translateX(-50%) translateY(50%);
  bottom: initial;
  top: -17px;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px colors.$color-primary-main;
    border-bottom: initial;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
  }
}

.tooltip-right-top .invalid-tooltip,
.tooltip-right-top .valid-tooltip,
.tooltip-right-top div.error {
  transform: translateX(0) translateY(50%);
  top: initial;
  left: initial;
  right: 0;
  bottom: initial;
  top: -17px;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px colors.$color-primary-main;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
    left: initial;
    right: 25px;
    margin-left: 0;
  }
}

.tooltip-left-top .invalid-tooltip,
.tooltip-left-top .valid-tooltip,
.tooltip-left-top div.error {
  transform: translateX(0) translateY(50%);
  top: initial;
  left: 0;
  right: initial;
  bottom: initial;
  top: -17px;

  &::before {
    content: "";
    position: absolute;
    top: initial;
    bottom: -5px;
    border-top: solid 5px colors.$color-primary-main;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    bottom: -4px;
    border-top: solid 5px $foreground-color;
    border-bottom: initial;
    left: 25px;
    right: initial;
    margin-left: 0;
  }
}

.tooltip-label-right .invalid-tooltip,
.tooltip-label-right .valid-tooltip,
.tooltip-label-right div.error {
  transform: translateX(0) translateY(-50%);
  top: 16px;
  left: 50px;

  &::before {
    content: "";
    position: absolute;
    left: -5px;
    right: initial;
    margin-left: 0;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px colors.$color-primary-main;
    bottom: initial;
    top: 12px;
    width: 5px;
  }

  &::after {
    content: "";
    position: absolute;
    top: initial;
    left: -4px;
    right: initial;
    margin-left: 0;
    width: 5px;
    border: initial;
    border-top: solid 5px transparent;
    border-bottom: solid 5px transparent;
    border-right: solid 5px $foreground-color;
    bottom: initial;
    top: 12px;
    width: 5px;
  }
}

.tooltip-label-right {
  .error-l-0 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 0px;
    }
  }

  .error-l-25 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 25px;
    }
  }

  .error-l-50 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 50px;
    }
  }

  .error-l-75 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 75px;
    }
  }

  .error-l-100 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 100px;
    }
  }

  .error-l-125 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 125px;
    }
  }

  .error-l-150 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 150px;
    }
  }

  .error-l-175 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 175px;
    }
  }

  .error-l-200 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 200px;
    }
  }

  .error-l-225 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 225px;
    }
  }

  .error-l-250 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 250px;
    }
  }

  .error-l-275 {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      left: 275px;
    }
  }

  .error-t-negative {

    .invalid-tooltip,
    .valid-tooltip,
    div.error {
      top: -10px;
    }
  }
}

.form-group.is-invalid {
  .invalid-tooltip {
    display: block;
  }
}

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  color: $foreground-color;
  border-radius: 0.2rem;
  right: 4px;
  color: colors.$success-color;
}

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  color: $foreground-color;
  border-radius: 0.2rem;
  right: 4px;
  color: colors.$error-color;
}
