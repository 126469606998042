// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@use './colors' as *;

//@font-face {
//  font-family: 'Merriweather Sans';
//  src: url('/assets/fonts/MerriweatherSans-VariableFont_wght.ttf');
//}
@font-face {
  font-family: 'Outfit';
  src: url('/assets/fonts/Outfit-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Oooh Baby';
  src: url('/assets/fonts/OoohBaby-Regular.ttf');
}
//
body {
  color: $color-primary-main;
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
}

.signature-text {
  font-family: 'Oooh Baby', cursive;
  font-size: 1.25rem;
  white-space: nowrap;
}

.blank-signature {
  padding-top: 40px;
  border-bottom: 1px solid $color-primary-200;
}

.link-text {
  cursor: pointer;
  color: $color-primary-accent-main;
}

.text-secondary-color {
  color: $color-primary-200;
}

.valid-doctor {
  margin-top: 1rem;
  font-size: 1.25em;
  font-style: italic;
  color: #28A745; // different from success color green
}

.primary-accent {
  color: $color-primary-accent-main;
}

.primary {
  color: $color-primary-main;
}
