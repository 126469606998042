// OLD VARIABLES

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;
$border-radius-20: 20px;

// TODO: remove these colors
$theme-color-blue-yale: #145388;
$theme-color-blue-olympic: #008ecc;
$theme-color-gray: #d0d0d0;
$theme-color-light-gray: #f9f9f9;
$theme-color-light-blue: #4a90e2;

$blue: #3d6298;
$orange: #F08C16;

$nav-link-padding-x: 1.5rem;
$nav-tabs-link-active-color: $orange;
$nav-tabs-border-color: #007bff;
$nav-tabs-link-active-border-color: #74ABCD #74ABCD #FFF;
$nav-tabs-border-radius: .5em;
$navbar-light-brand-color: $blue;
$navbar-light-color: rgba($blue, .5);

// NEW THEME STYLES BELOW AS OF V 1.10, ABOVE SHOULD BE DEPRECATED (Josh - 9/17/23)

$primary-blue: #507AB3;
$primary-orange: #E58B3C;
$primary-black: #4A4A4A;
$primary-red: #FF0000; 
$primary-white: #FFFFFF; 
$primary-grey: #A6A7A7;

$accent-blue-light: #D0DFEC;
$accent-blue-lighter: #E6EFF5;
$accent-blue-lightest: #F2F7FA;

$secondary-blue: #05B9D1;
$secondary-grey: #7f7f7f