@use "src/styles/abstracts/variables" as *;

#report {
  color: $primary-black;
}

.report-section {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $accent-blue-light;
  align-items: flex-start;
}

.report-header {
  margin-right: 30px;
  width: 140px;
}

.no-header .section-content {
  padding-left: 195px;
}

.section-content {
  padding-top: 4px;
  font-size: 16px;
  flex: 1;
}

.pl-25 tr td:first-child, .ml-25 {
  padding-left: 25px;
}

.section-content td {
  vertical-align: top;
  padding-bottom: 10px;
}

.section-content .report-label, .section-content .section-label, .bold-text {
  text-transform: uppercase;
  font-weight: bold;
}

.section-content td.report-label {
  padding-right: 25px;
  width: 1%;
  white-space: nowrap;
}

.report-value table {
  margin-bottom: 5px;
}

.report-value td {
  padding-right: 15px;
}

.section-value-wrapper {
  flex: 1;
}

tr, td {
  padding: 0;
}

h4 {
  color: $primary-blue;
}

.highlight-row {
  background-color: $accent-blue-lightest;
}

.uppercase {
  text-transform: uppercase;
}

.no-break {
  break-inside: avoid;
}

.text-out-of-range {
  color: $primary-red;
}

.two-column-layout {
  column-count: 2;
  column-gap: 23px;
}

.label-value-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-top: 10px;
}

.label-value-wrapper div.section-label {
  flex-basis: 133px;
}

.label-value-wrapper div.section-value{
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.text-out-of-range, .no-wrap {
  text-wrap: nowrap;
}

.text-out-of-range {
  margin-left: 5px;
}