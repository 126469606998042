// -----------------------------------------------------------------------------
// This file contains all styles related to containers and main body of the application
// -----------------------------------------------------------------------------

@use '../base/colors';
@use './menu';
@use '../abstracts/mixins';

body {
  background: colors.$color-gray-100;

  &.background {
    height: 100%;

    main {
      margin: 0 !important;
      height: 100%;

      .container {
        height: 100%;
      }
    }
  }
}
.main-outlet {
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 850px;
  background-color: colors.$color-white;
  border-radius: 10px;
  min-height: 45rem;
}

.container-fluid {
  padding-top: 0;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;

  .sticky-header-section {
    padding: 10px;
    background-color: #FFFFFF;
    border-bottom: 2px solid rgba(38, 83, 132, 0.2);
    margin: 0;
  }
}

.main-outlet-sm {
  margin-left: 0;
  margin-right: 0;
  padding-top: 5px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 850px;
  background-color: colors.$color-white;
  border-radius: 10px;
  min-height: 45rem;
}

.container {
  margin-left: 5px;
  margin-right: 5px;
}

.sticky-header-section {
  padding: 10px;
  background-color: #FFFFFF;
  border-bottom: 2px solid rgba(38, 83, 132, 0.2);
  margin: 0 0 0 -30px;
}

#app-container {
  &.sub-hidden .main-outlet,
  &.menu-sub-hidden .main-outlet,
  &.menu-hidden .main-outlet {
    margin-left: menu.$main-menu-width + menu.$main-margin;
  }

  &.main-hidden .main-outlet,
  &.menu-hidden .main-outlet {
    margin-left: menu.$main-margin;
  }

  &.menu-main-hidden {
    .main-outlet {
      margin-left: menu.$main-margin + menu.$sub-menu-width;
    }

    &.menu-hidden {
      .main-outlet {
        margin-left: menu.$main-margin;
      }
    }
  }

  @include mixins.respond-below(xl) {

    &.sub-hidden .main-outlet,
    &.menu-sub-hidden .main-outlet,
    &.menu-hidden .main-outlet {
      margin-left: menu.$main-menu-width-lg + menu.$main-margin-lg;
    }

    &.main-hidden .main-outlet,
    &.menu-hidden .main-outlet {
      margin-left: menu.$main-margin-lg;
    }

    &.menu-main-hidden {
      .main-outlet {
        margin-left: menu.$main-margin-lg + menu.$sub-menu-width-lg;
      }

      &.menu-hidden {
        .main-outlet {
          margin-left: menu.$main-margin-lg;
        }
      }
    }
  }

  @include mixins.respond-below(lg) {

    &.sub-hidden .main-outlet,
    &.menu-sub-hidden .main-outlet,
    &.menu-hidden .main-outlet {
      margin-left: menu.$main-menu-width-md + menu.$main-margin-md;
    }

    &.main-hidden .main-outlet,
    &.menu-hidden .main-outlet {
      margin-left: menu.$main-margin-md;
    }

    &.menu-main-hidden {
      .main-outlet {
        margin-left: menu.$main-margin-md + menu.$sub-menu-width-md;
      }

      &.menu-hidden {
        .main-outlet {
          margin-left: menu.$main-margin-md;
        }
      }
    }
  }
}
