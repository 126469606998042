// -----------------------------------------------------------------------------
// This file contains all styles related to the notifications of the site/application.
// -----------------------------------------------------------------------------

// These styles are for the <simple-notification> component that's utilized throughout the app

@use "../base/colors";
@use "../abstracts/variables";

$foreground-color: colors.$color-white;
$background-color: colors.$color-gray-400;
$theme-color-1: colors.$color-primary-main;
$theme-color-2: colors.$color-primary-200;

.simple-notification {
  border-radius: variables.$border-radius;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.simple-notification .icon {
  padding-top: 10px !important;
  width: 40px !important;
  height: 50px !important;
}

.simple-notification .sn-title {
  font-size: 1rem !important;
  line-height: 1 !important;
  margin-bottom: 0.5rem !important;
}

.simple-notification .sn-content {
  font-size: 0.8rem !important;
}

.simple-notification.success {
  color: colors.$success-color !important;
  background-color: mix(colors.$success-color, $background-color, 20%) !important;
  border-color: rgba(colors.$success-color, 0.1) !important;

  .icon svg {
    fill: colors.$success-color !important;
  }
}

.simple-notification .sn-progress-loader {
  margin: 15px 7%;
  height: 2px !important;
  width: 86% !important;
  top: initial !important;
  bottom: 0 !important;
  opacity: 0.75;
}

.simple-notification.success .sn-progress-loader span {
  border-radius: variables.$border-radius;
}

.simple-notification.bare {
  .icon {
    display: none;
  }
}

.simple-notification.outline.primary {
  background-color: $foreground-color !important;
  border: 1px solid $theme-color-1 !important;
  color: colors.$color-primary-main !important;

  .sn-title {
    color: $theme-color-1 !important;
  }

  .sn-progress-loader span {
    background: $theme-color-1 !important;
  }
}

.simple-notification.outline.secondary {
  background-color: $foreground-color !important;
  border: 1px solid $theme-color-2 !important;
  color: colors.$color-primary-main !important;

  .sn-title {
    color: $theme-color-2 !important;
  }
}

.simple-notification.outline.info {
  background-color: $foreground-color !important;
  border: 1px solid colors.$info-color !important;
  color: colors.$color-primary-main !important;

  .sn-title {
    color: colors.$info-color !important;
  }

  .icon svg {
    fill: colors.$info-color !important;
  }
}

.simple-notification.outline.success {
  background-color: $foreground-color !important;
  border: 1px solid colors.$success-color !important;
  color: colors.$color-primary-main !important;

  .sn-title {
    color: colors.$success-color !important;
  }

  .icon svg {
    fill: colors.$success-color !important;
  }
}

.simple-notification.outline.warn {
  background-color: $foreground-color !important;
  border: 1px solid colors.$warning-color !important;
  color: colors.$color-primary-main !important;

  .sn-title {
    color: colors.$warning-color !important;
  }

  .icon svg {
    fill: colors.$warning-color !important;
  }
}

.simple-notification.outline.error {
  background-color: $foreground-color !important;
  border: 1px solid colors.$error-color !important;
  color: colors.$color-primary-main !important;

  .sn-title {
    color: colors.$error-color !important;
  }

  .icon svg {
    fill: colors.$error-color !important;
  }
}

.simple-notification.primary {
  color: $theme-color-1 !important;
  background-color: mix($theme-color-1, $background-color, 20%) !important;
  border-color: rgba($theme-color-1, 0.1) !important;

  .sn-progress-loader {
    background: rgba($color: $theme-color-1, $alpha: 0.1) !important;

    span {
      background: rgba($color: $theme-color-1, $alpha: 0.5) !important;
    }
  }
}

.simple-notification.secondary {
  color: $theme-color-2 !important;
  background-color: mix($theme-color-2, $background-color, 20%) !important;
  border-color: rgba($theme-color-2, 0.1) !important;

  .sn-progress-loader {
    background: rgba($color: $theme-color-2, $alpha: 0.1) !important;

    span {
      background: rgba($color: $theme-color-2, $alpha: 0.5) !important;
    }
  }
}

.simple-notification.info {
  color: colors.$info-color !important;
  background-color: mix(colors.$info-color, $background-color, 20%) !important;
  border-color: rgba(colors.$info-color, 0.1) !important;

  .icon svg {
    fill: colors.$info-color !important;
  }

  .sn-progress-loader {
    background: rgba($color: colors.$info-color, $alpha: 0.1) !important;

    span {
      background: rgba($color: colors.$info-color, $alpha: 0.5) !important;
    }
  }
}

.simple-notification.error {
  color: colors.$error-color !important;
  background-color: mix(colors.$error-color, $background-color, 20%) !important;
  border-color: rgba(colors.$error-color, 0.1) !important;

  .icon svg {
    fill: colors.$error-color !important;
  }

  .sn-progress-loader {
    background: rgba($color: colors.$error-color, $alpha: 0.1) !important;

    span {
      background: rgba($color: colors.$error-color, $alpha: 0.5) !important;
    }
  }
}

.simple-notification.success {
  color: colors.$success-color !important;
  background-color: mix(colors.$success-color, $background-color, 20%) !important;
  border-color: rgba(colors.$success-color, 0.1) !important;

  .icon svg {
    fill: colors.$success-color !important;
  }

  .sn-progress-loader {
    background: rgba($color: colors.$success-color, $alpha: 0.1) !important;

    span {
      background: rgba($color: colors.$success-color, $alpha: 0.5) !important;
    }
  }
}

.simple-notification.warn {
  color: colors.$warning-color !important;
  background-color: mix(colors.$warning-color, $background-color, 20%) !important;
  border-color: rgba(colors.$warning-color, 0.1) !important;

  .icon svg {
    fill: colors.$warning-color !important;
  }

  .sn-progress-loader {
    background: rgba($color: colors.$warning-color, $alpha: 0.1) !important;

    span {
      background: rgba($color: colors.$warning-color, $alpha: 0.5) !important;
    }
  }
}