// -----------------------------------------------------------------------------
// This file contains all styles related to the loading icon of the site/application.
// -----------------------------------------------------------------------------
@use '../abstracts/mixins';
@use '../base/colors';

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(colors.$color-primary-main, 0.2);
  border-radius: 50%;
  border-top-color: colors.$color-primary-main;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: relative;
  z-index: 1;
}

.component-loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(colors.$color-primary-main, 0.2);
  border-radius: 50%;
  border-top-color: colors.$color-primary-main;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  position: relative;
  z-index: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
