@use "src/styles/base/colors";
// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.font-size-header {
  font-size: 16px;
  font-weight: 400;
}

.ml-0 {
  margin-left: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mr-10 {
  margin-right: 10px;
}

.notes-wrap {
  white-space: pre-wrap;
}

.section-title {
  font-size: 16px;
  font-weight: normal;
}

.message {
  font-size: 14px;
  font-weight: normal;
}


.buttonArea {
  margin-top: -15px;
}
